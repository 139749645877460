import React from 'react';
import {
  NavLink, Media, Container, Row, Col,
} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import ScrollTop from './ScrollTop';

const Footer = () => (
  <LazyLoad once offset={100}>
    <footer>
      <Container>
        <Row>
          <Col xs={7} md={8} className="align-self-start">
            <Row>
              <Col xs={12} className="justify-content-start">
                <span>©</span>
                { new Date().getFullYear() } SUZUKI AGRONOMIA
                <div className="mt-1">Todos los derechos reservados</div>
                <div className="mt-1">
                  Defensa del consumidor: Para consultas y/o denuncias
                  {' '}
                  <NavLink
                    href="https://buenosaires.gob.ar/jefaturadegabinete/atencion-ciudadana-y-gestion-comunal/defensa-al-consumidor" 
                    target="_blank" 
                    style={{ 
                      fontWeight: 'bold', textDecoration: 'underline', 
                      display: 'inline', padding: '0px 0px'
                    }}
                  >
                    ingrese aquí
                  </NavLink>
                </div>
              </Col>
              <Col xs={12} className="justify-content-start d-flex pt-5 flex-column flex-md-row">
                <Media tag="span" className="align-items-center">Potenciado por</Media>
                <Media tag="span" className="d-md-inline-block">
                  <NavLink className="green-interactive pl-0 pl-md-1" target="_blank" href="http://www.greeninteractivo.com" rel="noopener">Green Interactivo</NavLink>
                </Media>
              </Col>
            </Row>
          </Col>
          <Col xs={5} md={4}>
            <Row>
              <Col xs={12} className="justify-content-end d-flex">
                <ScrollTop />
              </Col>
              <Col xs={12} className="justify-content-end d-flex">
                <Media tag="span" className="pr-4">Seguinos en:</Media>
              </Col>
              <Col xs={12} className="justify-content-end d-flex">
                <NavLink target="_blank" href="https://www.instagram.com/suzukiagronomia/" rel="noopener" className="d-inline px-1">
                  <Media alt="Agrobikes" src="/assets/instagram.svg" className="d-inline-block" width="100" height="100" />
                </NavLink>
                <NavLink target="_blank" href="https://www.facebook.com/profile.php?id=100075555490698" rel="noopener" className="d-inline px-1">
                  <Media alt="Agrobikes" src="/assets/facebook.svg" className="d-inline-block" width="100" height="100" />
                </NavLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  </LazyLoad>
);

export default Footer;
